import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, SlicePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import {
    faArrowAltCircleRight,
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCalendarTimes,
    faChartBar,
    faCheckCircle as farCheckCircle,
    faCheckSquare,
    faCircle as farCircle,
    faClipboard,
    faClock,
    faComment,
    faCommentDots,
    faComments,
    faCreditCard,
    faEdit,
    faEnvelope as farEnvelope,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFileWord,
    faHandshake,
    faPaperPlane as farPaperPlane,
    faPlayCircle,
    faQuestionCircle,
    far,
    faSquare,
    faTimesCircle as farTimesCircle,
    faTrashAlt,
    faUser as farUser,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowCircleDown,
    faArrowCircleUp,
    faAt,
    faBan,
    faBars,
    faCashRegister,
    faCertificate,
    faCheck,
    faCheckCircle as fasCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle as fasCircle,
    faDesktop,
    faDollarSign,
    faDownload,
    faEllipsisH,
    faEnvelope as fasEnvelope,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faFileExport,
    faFileImport,
    faFileInvoiceDollar,
    faFilm,
    faFilter,
    faFolderOpen,
    faGavel,
    faGlobe,
    faHandHoldingUsd,
    faHistory,
    faInfoCircle,
    faKey,
    faLock,
    faMinusCircle,
    faMobileAlt,
    faMoneyCheckAlt,
    faPaperclip,
    faPaperPlane as fasPaperPlane,
    faPencilAlt,
    faPhoneAlt,
    faPlay,
    faPlus,
    faPlusCircle,
    faPrint,
    faQuestion,
    fas,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faShieldAlt,
    faSignature,
    faSortAmountDown,
    faSpinner,
    faStop,
    faSync,
    faTasks,
    faTh,
    faTimes,
    faTimesCircle as fasTimesCircle,
    faTv,
    faUndo,
    faUniversity,
    faUnlock,
    faUpload,
    faUser,
    faUserTag,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormModule } from '../../gamma/form/form.module';
import { SharedModule } from '../../gamma/shared/shared.module';
import { U2000_FormValidationProvider } from '../U2000_core';
import { U2000_PasswordQuestionComponent } from './U2000_components/U2000-passwordquestion/U2000_passwordquestion.component';
import { U2000_BamboraTokenComponent } from './U2000_components/U2000_creditcardtoken/U2000_bamboratoken/U2000_bamboratoken.component';
import { U2000_CreditCardTokenComponent } from './U2000_components/U2000_creditcardtoken/U2000_creditcardtoken.component';
import { U2000_MonerisTokenComponent } from './U2000_components/U2000_creditcardtoken/U2000_moneristoken/U2000_moneristoken.component';
import { U2000_GridComponent } from './U2000_components/U2000_grid/U2000_grid.component';
import { U2000_NavWizardComponent } from './U2000_components/U2000_navwizard/U2000_navwizard.component';
import { U2000_SectionTitleComponent } from './U2000_components/U2000_sectiontitle/U2000_sectiontitle.component';
import { U2000_SeparatorComponent } from './U2000_components/U2000_separator/U2000_separator.component';
import { U2000_BusyIndicatorOverlayDirective } from './U2000_directives/U2000_busyindicator-overlay/U2000_busyindicator-overlay.directive';
import { U2000_ClickOutsideDirective } from './U2000_directives/U2000_clickoutside.directive';
import { U2000_PostalCodePipe } from './U2000_pipes/U2000_postalcode.pipe';
import { U2000_TelephonePipe } from './U2000_pipes/U2000_telephone.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        PaginationModule.forRoot(),
        TabsModule.forRoot(),
        AlertModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ProgressbarModule.forRoot(),
        FormModule.withConfig({
            provider: U2000_FormValidationProvider,
        }),
        FontAwesomeModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        PaginationModule,
        TabsModule,
        AlertModule,
        TooltipModule,
        PopoverModule,
        BsDropdownModule,
        BsDatepickerModule,
        ProgressbarModule,
        ModalModule,
        FontAwesomeModule,
        U2000_PostalCodePipe,
        U2000_TelephonePipe,
        U2000_BusyIndicatorOverlayDirective,
        U2000_ClickOutsideDirective,
        U2000_NavWizardComponent,
        U2000_GridComponent,
        U2000_PasswordQuestionComponent,
        U2000_SeparatorComponent,
        U2000_SectionTitleComponent,
        U2000_CreditCardTokenComponent,
        U2000_BamboraTokenComponent,
        U2000_MonerisTokenComponent,
    ],
    declarations: [
        U2000_PostalCodePipe,
        U2000_TelephonePipe,
        U2000_BusyIndicatorOverlayDirective,
        U2000_ClickOutsideDirective,
        U2000_NavWizardComponent,
        U2000_GridComponent,
        U2000_PasswordQuestionComponent,
        U2000_SeparatorComponent,
        U2000_SectionTitleComponent,
        U2000_CreditCardTokenComponent,
        U2000_BamboraTokenComponent,
        U2000_MonerisTokenComponent,
    ],
    providers: [CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, SlicePipe, U2000_PostalCodePipe, U2000_TelephonePipe, { provide: TooltipConfig, useFactory: getTooltipConfig }],
})
export class U2000_SharedModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIconPacks(far);
        library.addIcons(faAngleDown);
        library.addIcons(faAngleLeft);
        library.addIcons(faAngleRight);
        library.addIcons(faAngleUp);
        library.addIcons(faArrowCircleDown);
        library.addIcons(faArrowAltCircleRight);
        library.addIcons(faArrowCircleUp);
        library.addIcons(faAt);
        library.addIcons(faBan);
        library.addIcons(faBars);
        library.addIcons(faBitcoin);
        library.addIcons(faBuilding);
        library.addIcons(faCalendar);
        library.addIcons(faCalendarAlt);
        library.addIcons(faCalendarTimes);
        library.addIcons(faCashRegister);
        library.addIcons(faCertificate);
        library.addIcons(faChartBar);
        library.addIcons(faCheck);
        library.addIcons(fasCheckCircle);
        library.addIcons(farCheckCircle);
        library.addIcons(faCheckSquare);
        library.addIcons(faChevronDown);
        library.addIcons(faChevronLeft);
        library.addIcons(faChevronRight);
        library.addIcons(faChevronUp);
        library.addIcons(fasCircle);
        library.addIcons(farCircle);
        library.addIcons(faClipboard);
        library.addIcons(faClock);
        library.addIcons(faComment);
        library.addIcons(faCommentDots);
        library.addIcons(faComments);
        library.addIcons(faCreditCard);
        library.addIcons(faDesktop);
        library.addIcons(faDollarSign);
        library.addIcons(faDownload);
        library.addIcons(faEdit);
        library.addIcons(faEllipsisH);
        library.addIcons(fasEnvelope);
        library.addIcons(farEnvelope);
        library.addIcons(faExclamation);
        library.addIcons(faExclamationCircle);
        library.addIcons(faExclamationTriangle);
        library.addIcons(faEye);
        library.addIcons(faEyeSlash);
        library.addIcons(faFileImport);
        library.addIcons(faFileExport);
        library.addIcons(faFileInvoiceDollar);
        library.addIcons(faFile);
        library.addIcons(faFileAlt);
        library.addIcons(faFileExcel);
        library.addIcons(faFileImage);
        library.addIcons(faFilePdf);
        library.addIcons(faFileWord);
        library.addIcons(faFilter);
        library.addIcons(faFilm);
        library.addIcons(faFolderOpen);
        library.addIcons(faGavel);
        library.addIcons(faGlobe);
        library.addIcons(faHandHoldingUsd);
        library.addIcons(faHandshake);
        library.addIcons(faHistory);
        library.addIcons(faInfoCircle);
        library.addIcons(faKey);
        library.addIcons(faLock);
        library.addIcons(faMinusCircle);
        library.addIcons(faMobileAlt);
        library.addIcons(faMoneyCheckAlt);
        library.addIcons(fasPaperPlane);
        library.addIcons(farPaperPlane);
        library.addIcons(faPaperclip);
        library.addIcons(faPencilAlt);
        library.addIcons(faPhoneAlt);
        library.addIcons(faPlay);
        library.addIcons(faPlayCircle);
        library.addIcons(faPlus);
        library.addIcons(faPlusCircle);
        library.addIcons(faPrint);
        library.addIcons(faQuestion);
        library.addIcons(faQuestionCircle);
        library.addIcons(faSearch);
        library.addIcons(faSearchMinus);
        library.addIcons(faSearchPlus);
        library.addIcons(faShieldAlt);
        library.addIcons(faSignature);
        library.addIcons(faSortAmountDown);
        library.addIcons(faSpinner);
        library.addIcons(faSquare);
        library.addIcons(faStop);
        library.addIcons(faSync);
        library.addIcons(faTasks);
        library.addIcons(faTh);
        library.addIcons(faTimes);
        library.addIcons(farTimesCircle);
        library.addIcons(fasTimesCircle);
        library.addIcons(faTrashAlt);
        library.addIcons(faTv);
        library.addIcons(faUndo);
        library.addIcons(faUniversity);
        library.addIcons(faUnlock);
        library.addIcons(faUpload);
        library.addIcons(faUser);
        library.addIcons(faUserTag);
        library.addIcons(farUser);
    }
}

export function getTooltipConfig(): TooltipConfig {
    return Object.assign(new TooltipConfig(), {
        container: 'body',
    });
}
