import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BusyIndicatorService } from '../../../gamma/busyindicator';
import { U2000_LogService } from './U2000_log.service';

declare let customcheckout: () => BamboraCustomCheckout;

export interface BamboraCustomCheckout {
    create(type, ...options: any[]): BamboraCustomCheckoutFluid;
    on(eventName, callback);
    createToken(callback);
}

export interface BamboraCustomCheckoutFluid {
    // field object API methods:
    mount(type, ...options: any[]): BamboraCustomCheckoutFluid;
    unmount(): BamboraCustomCheckoutFluid;
    focus(): BamboraCustomCheckoutFluid;
    blur(): BamboraCustomCheckoutFluid;
    clear(): BamboraCustomCheckoutFluid;
    update(): BamboraCustomCheckoutFluid;
}

export interface BamboraCustomCheckoutTokenResult {
    code: string; // The HTTP status code of the tokenization request.
    error: BamboraError;
    token: string; // Only present if no error. The payment token result.
    last4: string; // Only present if no error. The last 4 digits of the card number.
    expiryMonth: string; // Only present if no error. The expiry month of the card.
    expiryYear: string; // Only present if no error. The expiry year of the card.
}

export interface BamboraError {
    field: string;
    type: 'CardNumberInvalid' | 'ExpiryIsNotSet' | 'ExpiryIsInThePast' | 'CvvNotSet' | 'TokenizationValidationFailed' | 'TokenizationFailed' | 'TokenizationNoResponse';
    message: string;
}

@Injectable()
export class U2000_BamboraCustomCheckoutService {
    brand: 'amex' | 'diners' | 'discover' | 'jcb' | 'mastercard' | 'visa' | 'maestro';
    isCardNumberComplete: boolean;
    isCVVComplete: boolean;
    isExpiryComplete: boolean;

    tokenizationResult: BamboraCustomCheckoutTokenResult;

    private cardNumberField: BamboraCustomCheckoutFluid;
    private cvvField: BamboraCustomCheckoutFluid;
    private expiryField: BamboraCustomCheckoutFluid;

    private formId: string;
    private tokenSuccess: (token: string) => void;
    private tokenError: (error: string) => void;
    private customCheckout: BamboraCustomCheckout;
    private errorsMatching: { [bamboraError: string]: { message: string; interpolateParams?: any } };
    private mounted: boolean;
    private initialized = false;

    constructor(private translateService: TranslateService, private busyIndicator: BusyIndicatorService, private logService: U2000_LogService) {
        this.errorsMatching = {
            CardNumberInvalid: { message: 'U2000-0031' },
            ExpiryIsNotSet: { message: 'U2000-0032' },
            ExpiryIsInThePast: { message: 'U2000-0032' },
            CvvNotSet: { message: 'U2000-0033' },
            TokenizationValidationFailed: { message: 'U2000-0034' },
            TokenizationFailed: { message: 'U2000-0034' },
            TokenizationNoResponse: { message: 'U2000-0034' },
        };
        this.isCardNumberComplete = false;
        this.isCVVComplete = false;
        this.isExpiryComplete = false;
        this.mounted = false;
    }

    initialize(formId: string, tokenSuccess: (token: string) => void, tokenError: (error: string) => void) {
        const self = this;
        try {
            this.customCheckout = customcheckout();
            this.formId = formId;
            this.tokenSuccess = tokenSuccess;
            this.tokenError = tokenError;
            this.initialized = true;

            this.createInputs();
            this.addListeners();

            // listen for submit button
            if (document.getElementById(this.formId) !== null) {
                document.getElementById(this.formId).addEventListener('submit', self.onSubmit.bind(self));
            }
            return true;
        } catch (event) {
            this.logService.log(`JS - U2000 - Bambora script is not loaded.\n${event.error}\n${event.error.stack}`, true);
            return false;
        }
    }

    destroy() {
        this.unmount();
        this.customCheckout = null;
    }

    createInputs() {
        const options: { placeholder?: string; style?: any; classes?: string; brands?: string[] } = {};

        const style = {
            base: {
                fontSize: '16px',
                fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
                paddingTop: '2px',
                color: '#555555',
            },
        };

        options.style = style;
        // options.brands = ['visa', 'mastercard'];

        this.cardNumberField = this.customCheckout.create('card-number', options);
        this.cardNumberField.mount('#' + this.formId + '-card-number');
        this.cvvField = this.customCheckout.create('cvv', options);
        this.cvvField.mount('#' + this.formId + '-card-cvv');
        this.expiryField = this.customCheckout.create('expiry', options);
        this.expiryField.mount('#' + this.formId + '-card-expiry');
        this.mounted = true;
    }

    mount() {
        if (!this.mounted && this.initialized) {
            this.cardNumberField.mount('#' + this.formId + '-card-number');
            this.cvvField.mount('#' + this.formId + '-card-cvv');
            this.expiryField.mount('#' + this.formId + '-card-expiry');
            this.addListeners();
            this.mounted = true;
        }
    }

    unmount() {
        if (this.mounted && this.initialized) {
            this.cardNumberField.unmount();
            this.cvvField.unmount();
            this.expiryField.unmount();
            this.removeListeners();
            this.mounted = false;
        }
    }

    addListeners() {
        const self = this;

        this.customCheckout.on('brand', event => {
            let cardLogo = 'none';
            if (event.brand && event.brand !== 'unknown') {
                this.brand = event.brand;
                const filePath = 'https://cdn.na.bambora.com/downloads/images/cards/' + event.brand + '.svg';
                cardLogo = 'url(' + filePath + ')';
            }
            document.getElementById(this.formId + '-card-number').style.backgroundImage = cardLogo;
        });

        this.customCheckout.on('blur', event => {
            let bootStrapParent: HTMLElement;
            if (event.field === 'card-number') {
                bootStrapParent = document.getElementById(this.formId + '-card-number');
            } else if (event.field === 'cvv') {
                bootStrapParent = document.getElementById(this.formId + '-card-cvv');
            } else if (event.field === 'expiry') {
                bootStrapParent = document.getElementById(this.formId + '-card-expiry');
            }
            if (bootStrapParent !== null) {
                bootStrapParent.classList.remove('bambora-is-focused');
            }
        });

        this.customCheckout.on('focus', event => {
            let bootStrapParent: HTMLElement;
            if (event.field === 'card-number') {
                bootStrapParent = document.getElementById(this.formId + '-card-number');
            } else if (event.field === 'cvv') {
                bootStrapParent = document.getElementById(this.formId + '-card-cvv');
            } else if (event.field === 'expiry') {
                bootStrapParent = document.getElementById(this.formId + '-card-expiry');
            }

            if (bootStrapParent !== null) {
                bootStrapParent.classList.add('bambora-is-focused');
            }
        });

        this.customCheckout.on('empty', event => {
            if (event.empty) {
                if (event.field === 'card-number') {
                    this.isCardNumberComplete = false;
                } else if (event.field === 'cvv') {
                    this.isCVVComplete = false;
                } else if (event.field === 'expiry') {
                    this.isExpiryComplete = false;
                }
            }
        });

        this.customCheckout.on('complete', event => {
            if (event.field === 'card-number') {
                this.isCardNumberComplete = true;
                self.hideErrorForId(this.formId + '-card-number');
            } else if (event.field === 'cvv') {
                this.isCVVComplete = true;
                self.hideErrorForId(this.formId + '-card-cvv');
            } else if (event.field === 'expiry') {
                this.isExpiryComplete = true;
                self.hideErrorForId(this.formId + '-card-expiry');
            }
        });

        this.customCheckout.on('error', event => {
            const error = this.translateError(event.type);
            if (event.field === 'card-number') {
                this.isCardNumberComplete = false;
                self.showErrorForId(this.formId + '-card-number', error);
            } else if (event.field === 'cvv') {
                this.isCVVComplete = false;
                self.showErrorForId(this.formId + '-card-cvv', error);
            } else if (event.field === 'expiry') {
                this.isExpiryComplete = false;
                self.showErrorForId(this.formId + '-card-expiry', error);
            }
        });
    }

    removeListeners() {
        this.customCheckout.on('brand', null);
        this.customCheckout.on('blur', null);
        this.customCheckout.on('focus', null);
        this.customCheckout.on('empty', null);
        this.customCheckout.on('complete', null);
        this.customCheckout.on('error', null);
    }

    private translateError(error: string) {
        let message = error;
        if (this.errorsMatching[error] != null) {
            message = this.translateService.instant(this.errorsMatching[error].message, this.errorsMatching[error].interpolateParams);
        }
        return message;
    }

    onSubmit(event) {
        const self = this;
        this.busyIndicator.show(null, 'Bambora custom checkout (Service)');

        event.preventDefault();

        // self.toggleProcessingScreen();
        const callback = result => {
            // As Bambora keep a reference of the callback of old initialized bambora controller registered, we just tests that the form
            // is not still part of the DOM.
            if (document.getElementById(self.formId) != null) {
                this.tokenizationResult = result;
                this.busyIndicator.hide();
                if (result.error) {
                    self.processTokenError(result.error);
                } else {
                    self.processTokenSuccess(result.token);
                }
            }
        };
        if (this.mounted) {
            this.customCheckout.createToken(callback);
        } else {
            this.busyIndicator.hide(null, 'Bambora custom checkout (Service)');
        }
    }

    hideErrorForId(id) {
        const element = document.getElementById(id);

        if (element !== null) {
            const errorElement = document.getElementById(id + '-error');
            if (errorElement !== null) {
                errorElement.innerHTML = '';
            }

            const bootStrapParent = document.getElementById(id + '-fg');
            if (bootStrapParent !== null) {
                bootStrapParent.classList.remove('has-error');
            }
        } else {
            console.log('showErrorForId: Could not find ' + id);
        }
    }

    showErrorForId(id, message) {
        const element = document.getElementById(id);

        if (element !== null) {
            const errorElement = document.getElementById(id + '-error');
            if (errorElement !== null) {
                errorElement.innerHTML = message;
            }

            const bootStrapParent = document.getElementById(id + '-fg');
            if (bootStrapParent !== null) {
                bootStrapParent.classList.add('has-error');
            }
        } else {
            console.log('showErrorForId: Could not find ' + id);
        }
    }

    processTokenError(error: BamboraError) {
        const errorMatch = this.errorsMatching[error.type];

        if (error.type !== 'TokenizationValidationFailed') {
            this.tokenError(this.translateService.instant(errorMatch.message, errorMatch.interpolateParams));
        }
    }

    processTokenSuccess(token) {
        this.tokenSuccess(token);
    }

    clearFields() {
        this.cardNumberField.clear();
        this.expiryField.clear();
        this.cvvField.clear();
    }

    get valid() {
        return this.isCardNumberComplete && this.isCVVComplete && this.isExpiryComplete;
    }
}
