export const environment = {
    production: false,
    mock: false,
    apiUrl: '/main-sv/rest/transphere-main/',
    recaptchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    webSocketUrl: '',
    accountGenerationPassword: 'Acceo123!',
    accountGenerationFirstName: 'John',
    accountGenerationLastName: 'Doe',
    accountGenerationAnswer1: '1',
    accountGenerationAnswer2: '2',
    accountGenerationCompany: 'CIE ',
    accountGenerationAddress: '75 Queen',
    accountGenerationCity: 'Montréal',
    accountGenerationPostalCode: 'J4H7H4',
    accountGenerationPhone: '450-555-1234',
    accountGenerationFinInst: '815',
    accountGenerationTransit: '50030',
    accountGenerationAccNumber: '1234567',
    accountGenerationSubscription: {},
};
