import { NgModule } from '@angular/core';
import { NavigationEnd, NoPreloading, Router, RouterModule } from '@angular/router';
import { LocalStorageService } from '../gamma/localstorage';
import { RoutingService } from '../gamma/routing';
import { Routes } from '../gamma/routing/routing.service';
import { U2000_ApplicationMenuService } from './U2000_core/U2000_services/U2000_applicationmenu.service';
import { U2000_CustomPageComponent } from './U2000_custompage/U2000_custompage.component';
import { U2000_LoginNoBusinessLinkComponent } from './U2000_custompage/U2000_loginnobusinesslink.component';
import { U2000_MajorErrorComponent } from './U2000_custompage/U2000_majorerror.component';
import { U2000_SubscriptionComingSoonComponent } from './U2000_custompage/U2000_subscriptioncomingsoon.component';

const routes: Routes = [
    {
        path: 'U2000/e/m',
        component: U2000_MajorErrorComponent,
    },
    {
        path: 'U2000/w/s',
        component: U2000_SubscriptionComingSoonComponent,
    },
    {
        path: 'U2000/w/l',
        component: U2000_LoginNoBusinessLinkComponent,
    },
    {
        path: 'U2000/c/:id',
        component: U2000_CustomPageComponent,
    },
    {
        path: 'U2001',
        loadChildren: () => import('./U2001_home/U2001_home.module').then(m => m.U2001_HomeModule),
        public: true,
    },
    {
        path: 'U2012',
        loadChildren: () => import('./U2012_loginhelp/U2012_loginhelp.module').then(m => m.U2012_LoginHelpModule),
        public: true,
    },
    {
        path: 'U2014',
        loadChildren: () => import('./U2014_security/U2014_security.module').then(m => m.U2014_SecurityModule),
    },
    {
        path: 'U2023',
        loadChildren: () => import('./U2023_tutorialvideo/U2023_tutorialvideo.module').then(m => m.U2023_TutorialVideoModule),
    },
    {
        path: 'U2024',
        loadChildren: () => import('./U2024_reportlog/U2024_reportlog.module').then(m => m.U2024_ReportLogModule),
    },
    {
        path: 'U2028',
        loadChildren: () => import('./U2028_releasenote/U2028_releasenote.module').then(m => m.U2028_ReleaseNoteModule),
    },
    {
        path: 'U2111',
        loadChildren: () => import('./U2111_invoiceportalbinding/U2111_invoiceportalbinding.module').then(m => m.U2111_InvoicePortalBindingModule),
        public: true,
    },
    {
        path: 'U2112',
        loadChildren: () => import('./U2112_erpbinding/U2112_erpbinding.module').then(m => m.U2112_ERPBindingModule),
        public: true,
    },
    {
        path: 'U2113',
        loadChildren: () => import('./U2113_supplierinvitation/U2113_supplierinvitation.module').then(m => m.U2113_SupplierInvitationModule),
        public: true,
    },
    {
        path: 'U2114',
        loadChildren: () => import('./U2114_paymentgatewaybinding/U2114_paymentgatewaybinding.module').then(m => m.U2114_PaymentGatewayBindingModule),
        public: true,
    },
    {
        path: 'U2115',
        loadChildren: () => import('./U2115_affiliationinvitation/U2115_affiliationinvitation.module').then(m => m.U2115_AffiliationInvitationModule),
        public: true,
    },
    {
        path: 'U2116',
        loadChildren: () => import('./U2116_anonymoussupplierinvitation/U2116_anonymoussupplierinvitation.module').then(m => m.U2116_AnonymousSupplierInvitationModule),
        public: true,
    },
    {
        path: 'U2117',
        loadChildren: () => import('./U2117_padagreementinvitation/U2117_padagreementinvitation.module').then(m => m.U2117_PadAgreementInvitationModule),
        public: true,
    },
    {
        path: 'U2118',
        loadChildren: () => import('./U2118_rbbinding/U2118_rbbinding.module').then(m => m.U2118_RBBindingModule),
        public: true,
    },
    {
        path: 'U2121',
        loadChildren: () => import('./U2121_individualprofile/U2121_individualprofile.module').then(m => m.U2121_IndividualProfileModule),
    },
    {
        path: 'U2131',
        loadChildren: () => import('./U2131_organizationprofile/U2131_organizationprofile.module').then(m => m.U2131_OrganizationProfileModule),
    },
    {
        path: 'U2132',
        loadChildren: () => import('./U2132_affiliation/U2132_affiliation.module').then(m => m.U2132_AffiliationModule),
    },
    {
        path: 'U2133',
        loadChildren: () => import('./U2133_businesslink/U2133_businesslink.module').then(m => m.U2133_BusinesslinksModule),
    },
    {
        path: 'U2134',
        loadChildren: () => import('./U2134_organizationbilling/U2134_organizationbilling.module').then(m => m.U2134_OrganizationBillingModule),
    },
    {
        path: 'U2211',
        loadChildren: () => import('./U2211_subscriptionoffer/U2211_subscriptionoffer.module').then(m => m.U2211_SubscriptionOfferModule),
        public: true,
    },
    {
        path: 'U2212',
        loadChildren: () => import('./U2212_subscriptionfilter/U2212_subscriptionfilter.module').then(m => m.U2212_SubscriptionFilterModule),
        public: true,
    },
    {
        path: 'U2213',
        loadChildren: () => import('./U2213_subscription/U2213_subscription.module').then(m => m.U2213_SubscriptionModule),
        public: true,
        logout: true,
    },
    {
        path: 'U4101',
        loadChildren: () => import('./U4101_creditcardbankaccountsummary/U4101_creditcardbankaccountsummary.module').then(m => m.U4101_CreditCardBankAccountSummaryModule),
    },
    {
        path: 'U4301',
        loadChildren: () => import('./U4301_transactionssummary/U4301_transactionssummary.module').then(m => m.U4301_TransactionsSummaryModule),
    },
    {
        path: 'U4302',
        loadChildren: () => import('./U4302_transactionsdetail/U4302_transactionsdetail.module').then(m => m.U4302_TransactionsDetailModule),
    },
    {
        path: 'U4303',
        loadChildren: () => import('./U4303_anonymoustransaction/U4303_anonymoustransaction.module').then(m => m.U4303_AnonymousTransactionModule),
        public: true,
    },
    {
        path: 'U4401',
        loadChildren: () => import('./U4401_paymentgateway/U4401_paymentgateway.module').then(m => m.U4401_PaymentGatewayModule),
        public: true,
        logout: true,
    },
    {
        path: 'U4402',
        loadChildren: () => import('./U4402_invoiceportalpayment/U4402_invoiceportalpayment.module').then(m => m.U4402_InvoicePortalPaymentModule),
    },
    {
        path: 'U5101',
        loadChildren: () => import('./U5101_statementofaccount/U5101_statementofaccount.module').then(m => m.U5101_StatementOfAccountModule),
        public: true,
    },
    {
        path: 'U5102',
        loadChildren: () => import('./U5102_invoice/U5102_invoice.module').then(m => m.U5102_InvoiceModule),
        public: true,
    },
    {
        path: 'U5103',
        loadChildren: () => import('./U5103_padagreement/U5103_padagreement.module').then(m => m.U5103_PadAgreementModule),
    },
    {
        path: 'U5111',
        loadChildren: () => import('./U5111_sentinvoicestracking/U5111_sentinvoicestracking.module').then(m => m.U5111_SentInvoicesTrackingModule),
    },
    {
        path: 'U5121',
        loadChildren: () => import('./U5121_sentinvoice/U5121_sentinvoice.module').then(m => m.U5121_SentInvoiceModule),
    },
    {
        path: 'U5122',
        loadChildren: () => import('./U5122_invoicemodificationapproval/U5122_invoicemodificationapproval.module').then(m => m.U5122_InvoicemodificationapprovalModule),
        public: true,
    },
    {
        path: 'U5123',
        loadChildren: () => import('./U5123_padagreementconsole/U5123_padagreementconsole.module').then(m => m.U5123_PadAgreementConsoleModule),
    },
    {
        path: 'U5124',
        loadChildren: () => import('./U5124_pgreceivedpayment/U5124_pgreceivedpayment.module').then(m => m.U5124_PGReceivedPaymentModule),
    },
    {
        path: 'U5131',
        loadChildren: () => import('./U5131_receivedpayment/U5131_receivedpayment.module').then(m => m.U5131_ReceivedPaymentModule),
    },
    {
        path: 'U5131_PG',
        loadChildren: () => import('./U5131_receivedpayment/U5131_receivedpayment.module').then(m => m.U5131_ReceivedPaymentModule),
    },
    {
        path: 'U5132',
        loadChildren: () => import('./U5132_paymentreconciliation/U5132_paymentreconciliation.module').then(m => m.U5132_PaymentReconciliationModule),
    },
    {
        path: 'U5133',
        loadChildren: () => import('./U5133_bankreconciliation/U5133_bankreconciliation.module').then(m => m.U5133_BankReconciliationModule),
    },
    {
        path: 'U5191',
        loadChildren: () => import('./U5191_receivedpaymentreport/U5191_receivedpaymentreport.module').then(m => m.U5191_ReceivedPaymentReportModule),
    },
    {
        path: 'U5191_PG',
        loadChildren: () => import('./U5191_receivedpaymentreport/U5191_receivedpaymentreport.module').then(m => m.U5191_ReceivedPaymentReportModule),
    },
    {
        path: 'U6111',
        loadChildren: () => import('./U6111_supplierinvitationconsole/U6111_supplierinvitationconsole.module').then(m => m.U6111_SupplierInvitationConsoleModule),
    },
    {
        path: 'U6112',
        loadChildren: () => import('./U6112_businesslink/U6112_businesslink.module').then(m => m.U6112_BusinessLinkModule),
    },
    {
        path: 'U6113',
        loadChildren: () => import('./U6113_supplierrecord/U6113_supplierrecord.module').then(m => m.U6113_SupplierRecordModule),
    },
    {
        path: 'U6121',
        loadChildren: () => import('./U6121_eftlot/U6121_eftlot.module').then(m => m.U6121_EftLotModule),
    },
    {
        path: 'U6122',
        loadChildren: () => import('./U6122_eftapproval/U6122_eftapproval.module').then(m => m.U6122_EftApprovalModule),
    },
    {
        path: 'U6131',
        loadChildren: () => import('./U6131_sentpayment/U6131_sentpayment.module').then(m => m.U6131_SentPaymentModule),
    },
    {
        path: 'U6191',
        loadChildren: () => import('./U6191_sentpaymentreport/U6191_sentpaymentreport.module').then(m => m.U6191_SentPaymentReportModule),
    },
    {
        path: 'U6211',
        loadChildren: () => import('./U6211_paylot/U6211_paylot.module').then(m => m.U6211_PayLotModule),
    },
    {
        path: 'U6212',
        loadChildren: () => import('./U6212_payrollparty/U6212_payrollparty.module').then(m => m.U6212_PayrollPartyModule),
    },
    {
        path: 'U6221',
        loadChildren: () => import('./U6221_sentpayment/U6221_sentpayment.module').then(m => m.U6221_SentPaymentModule),
    },
    {
        path: 'U6311',
        loadChildren: () => import('./U6311_purchase/U6311_purchase.module').then(m => m.U6311_PurchaseModule),
        public: true,
    },
    {
        path: 'U6412',
        loadChildren: () => import('./U6412_interacfile/U6412_interacfile.module').then(m => m.U6412_InteracFileModule),
    },
    {
        path: 'U6414',
        loadChildren: () => import('./U6414_bankaccountwallet/U6414_bankaccountwallet.module').then(m => m.U6414_BankAccountWalletModule),
    },
    {
        path: 'U6422',
        loadChildren: () => import('./U6422_interacsentpayment/U6422_interacsentpayment.module').then(m => m.U6422_InteracSentPaymentModule),
    },
    {
        path: 'U6511',
        loadChildren: () => import('./U6511_transactionparty/U6511_transactionparty.module').then(m => m.U6511_TransactionPartyModule),
    },
    {
        path: 'U6512',
        loadChildren: () => import('./U6512_transactionlot/U6512_transactionlot.module').then(m => m.U6512_TransactionLotModule),
    },
    {
        path: 'U6531',
        loadChildren: () => import('./U6531_transactionpayment/U6531_transactionpayment.module').then(m => m.U6531_TransactionPaymentModule),
    },
    {
        path: 'U6532',
        loadChildren: () => import('./U6532_pendingtransaction/U6532_pendingtransaction.module').then(m => m.U6532_PendingTransactionModule),
    },
    { path: '**', pathMatch: 'full', redirectTo: 'U2011' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: NoPreloading,
            useHash: true,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {
    constructor(router: Router, routingService: RoutingService, private applicationMenuService: U2000_ApplicationMenuService, private localStorageService: LocalStorageService) {
        router.events.subscribe(event => {
            // Google analytics naviation automation.
            if (event instanceof NavigationEnd) {
                let url = event.urlAfterRedirects;
                // Errors and warning pages are not managed thru
                if (url.indexOf('/U2000/e/') > -1) {
                    url = '/U2000/e';
                } else if (url.indexOf('/U2000/w/') > -1) {
                    url = '/U2000/w';
                } else if (url.indexOf('/U2000/c/') > -1) {
                    url = '/U2000/c';
                }

                // Always navigate to the on on route change.
                window.scrollTo(0, 0);

                // Override the default logical unit shown when the user select a module
                let logicalUnit: string;
                if (url.indexOf('/') === url.lastIndexOf('/')) {
                    logicalUnit = url.substr(url.indexOf('/') + 1);
                } else {
                    logicalUnit = url.substr(url.indexOf('/') + 1, url.substr(url.indexOf('/') + 1).indexOf('/'));
                }
                if (logicalUnit.indexOf('U2') === -1 && logicalUnit.indexOf('U44') === -1 && logicalUnit.indexOf('U510') === -1) {
                    for (const application of applicationMenuService.applications) {
                        if (this.applicationMenuService.partOfModule(application.module)) {
                            this.localStorageService.set(this.applicationMenuService.getLocalStorageName(application.module), ['/' + logicalUnit]);
                            break;
                        }
                    }
                }
            }
        });
    }
}
