import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LogicalUnitInitMixin } from '../../../../../gamma/logicalunit';
import { ToastService } from '../../../../../gamma/toast';
import { U2000_AuthenticationService } from '../../../../U2000_core';
import { U2000_BamboraCustomCheckoutService } from '../../../../U2000_core/U2000_services/U2000_bambora.customcheckout.service';
import { IU2000_CreditCardTokenResponse } from '../U2000_creditcardtoken.component';

@Component({
    selector: 'u2000-bamboratoken',
    templateUrl: 'U2000_bamboratoken.component.html',
    styleUrls: ['U2000_bamboratoken.component.scss'],
    providers: [U2000_BamboraCustomCheckoutService],
})
export class U2000_BamboraTokenComponent implements OnDestroy, AfterViewInit {
    // There to avoid using the constructor's name that is not available when minified.
    static className = 'BamboraToken';

    @Input() service: LogicalUnitInitMixin;
    @Input() form: UntypedFormGroup;
    @Input() parentFormId: string;
    @Input() elementRef: ElementRef;
    @Output() submitEvent: EventEmitter<IU2000_CreditCardTokenResponse> = new EventEmitter();

    singleUseToken: string;

    constructor(
        private toastService: ToastService, //
        private bamboraController: U2000_BamboraCustomCheckoutService,
        public authService: U2000_AuthenticationService,
    ) {}

    ngAfterViewInit() {
        let bamboraOk = this.bamboraController.initialize(
            this.parentFormId,
            token => {
                // Called when Bambora tokenization success.
                if (token != null) {
                    this.singleUseToken = token;
                }

                let response: IU2000_CreditCardTokenResponse = {
                    token: token,
                    maskedCardNumber: String.padLeft(this.bamboraController.tokenizationResult.last4, 16, '*'),
                    cardType: this.determinePaymentMode(),
                };
                this.submitEvent.emit(response);
            },
            error => {
                this.toastService.error(error);
            },
        );

        if (!bamboraOk) {
            this.toastService.error(`lblBamboraCustomCheckoutDown`);
        }

        this.bamboraController.mount();
    }

    ngOnDestroy() {
        this.bamboraController.unmount();
        this.bamboraController.destroy();
    }

    get valid(): boolean {
        return this.bamboraController.valid && this.form.valid;
    }

    onError() {
        this.bamboraController.clearFields();
    }

    public buildRequest(): IU4402_CreditCardPaymentDtoRequest {
        return {
            singleUseToken: this.singleUseToken,
            maskedCreditCardNumber: String.padLeft(this.bamboraController.tokenizationResult.last4, 16, '*'),
            paymentMode: this.determinePaymentMode(),
        };
    }

    private determinePaymentMode(): IPaymentModeEnum {
        // Get the Bambora detected credit card type
        const ccType = this.bamboraController.brand.toUpperCase();
        const paymentModes = this.service.luConfigs.allPaymentModes;

        for (let i = 0; i < paymentModes.length; i++) {
            if (paymentModes[i] === ccType) {
                // We found a match with Transphere paymentMode
                return paymentModes[i];
            }
        }

        // This may be a Bambora accepted credit card so let it go though submit payment process
        return 'UNKNOWN';
    }

    isCreditCardMerchantSupportDebit() {
        return this.service.luConfigs.creditCardMerchantSupportDebit === false;
    }
}
