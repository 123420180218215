import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LogicalUnitInitMixin } from '../../../../../gamma/logicalunit';
import { ToastService } from '../../../../../gamma/toast';
import { U2000_AuthenticationService } from '../../../../U2000_core';
import { U2000_LogService } from '../../../../U2000_core/U2000_services/U2000_log.service';
import { IU2000_CreditCardTokenResponse } from '../U2000_creditcardtoken.component';

@Component({
    selector: 'u2000-moneristoken',
    templateUrl: 'U2000_moneristoken.component.html',
    styleUrls: ['U2000_moneristoken.component.scss'],
})
export class U2000_MonerisTokenComponent implements OnInit, OnDestroy, AfterViewInit {
    // There to avoid using the constructor's name that is not available when minified.
    static className = 'MonerisToken';

    @Input() service: LogicalUnitInitMixin;
    @Input() form: UntypedFormGroup;
    @Input() parentFormId: string;
    @Input() elementRef: ElementRef;
    @Input() monerisHostedTokenizationUrl: string;
    @Input() monerisHostedTokenizationId: string;
    @Output() submitEvent: EventEmitter<IU2000_CreditCardTokenResponse> = new EventEmitter();

    singleUseToken: string;
    monerisSrc: SafeResourceUrl;
    errorMsg: string;

    constructor(
        private toastService: ToastService, //
        private sanitizer: DomSanitizer,
        private translateService: TranslateService,
        //private Controller: U2000_BamboraCustomCheckoutService,
        public authService: U2000_AuthenticationService,
        private logService: U2000_LogService,
    ) {
        const self = this;
        if (window.addEventListener) {
            window.addEventListener('message', self.respMsg.bind(self), false);
        } else {
            if ((window as any).attachEvent) {
                (window as any).attachEvent('onmessage', self.respMsg.bind(self));
            }
        }
    }

    ngOnInit() {
        this.monerisSrc = this.buildMonerisSrc();
    }

    ngAfterViewInit() {
        const self = this;

        // listen for submit button

        if (document.getElementById(this.parentFormId) !== null) {
            document.getElementById(this.parentFormId).addEventListener('submit', self.doMonerisSubmit.bind(self));
        }

        /* let bamboraOk = this.bamboraController.initialize(
            this.parentFormId,
            token => {
                // Called when Bambora tokenization success.
                if (token != null) {
                    this.singleUseToken = token;
                }
                this.submitEvent.emit();
            },
            error => {
                this.toastService.error(error);
            },
        );

        if (!bamboraOk) {
            this.toastService.error(`lblBamboraCustomCheckoutDown`);
        } */
    }

    ngOnDestroy() {
        const self = this;
        if (document.getElementById(this.parentFormId) !== null) {
            document.getElementById(this.parentFormId).removeEventListener('submit', self.doMonerisSubmit.bind(self));
        }
        /* this.bamboraController.destroy(); */
    }

    get valid(): boolean {
        return true;
        /* return this.bamboraController.valid && this.form.valid; */
    }

    onError() {
        /* this.bamboraController.clearFields(); */
    }

    public buildRequest(): IU4402_CreditCardPaymentDtoRequest {
        return {
            singleUseToken: this.singleUseToken,
            /*  maskedCreditCardNumber: String.padLeft(this.bamboraController.tokenizationResult.last4, 16, '*'), */
            paymentMode: this.determinePaymentMode(),
        };
    }

    private determinePaymentMode(): IPaymentModeEnum {
        // Get the Bambora detected credit card type
        const ccType = 1; /*this.bamboraController.brand.toUpperCase(); */
        const paymentModes = this.service.luConfigs.allPaymentModes;

        for (let i = 0; i < paymentModes.length; i++) {
            if (paymentModes[i] === ccType) {
                // We found a match with Transphere paymentMode
                return paymentModes[i];
            }
        }

        // This may be a Bambora accepted credit card so let it go though submit payment process
        return 'UNKNOWN';
    }

    isCreditCardMerchantSupportDebit() {
        return this.service.luConfigs.creditCardMerchantSupportDebit === false;
    }

    onIframeError() {
        this.errorMsg = this.translateService.instant('U2000-0034');
    }

    doMonerisSubmit() {
        let monFrameRef = (document.getElementById('monerisFrame') as any).contentWindow;
        monFrameRef.postMessage('tokenize', this.monerisHostedTokenizationUrl);
        //change link according to table above
        return false;
    }

    respMsg(e) {
        if (typeof e.data === 'string') {
            let respData: { responseCode: string[]; errorMessage: string; bin: string; dataKey: string } = eval('(' + e.data + ')');
            if (respData.responseCode.some(x => x === '001')) {
                this.errorMsg = null;

                let response: IU2000_CreditCardTokenResponse = {
                    token: respData.dataKey,
                    maskedCardNumber: String.padLeft(respData.bin.substr(2), 16, '*'),
                };

                this.submitEvent.emit(response);
            } else {
                if (respData.responseCode.some(x => x === '943')) {
                    this.errorMsg = this.translateService.instant('U2000-0031');
                } else if (respData.responseCode.some(x => x === '944')) {
                    this.errorMsg = this.translateService.instant('U2000-0032');
                } else if (respData.responseCode.some(x => x === '945')) {
                    this.errorMsg = this.translateService.instant('U2000-0033');
                } else {
                    this.logService.log('Moneris tokenization error: ' + respData.responseCode, true);
                    this.errorMsg = this.translateService.instant('U2000-0034');
                }
            }
        }
    }

    buildMonerisSrc() {
        const baseUrl = this.monerisHostedTokenizationUrl;
        const params = {
            id: this.monerisHostedTokenizationId,
            pmmsg: 'true',
            css_body: 'font-family:%22Open%20Sans%22%2C%22Helvetica%20Neue%22%2Csans-serif',
            css_textbox:
                'margin-bottom:10px;margin-top:4px;display:block;width:100%;padding:0.375rem 0.75rem;font-size:1rem;font-weight:400;line-height:1.5;border-radius:0.25rem;color:%233A3C3D;background-color:%23fff;border:1px%20solid%20%23ced4da;',
            display_labels: '1',
            pan_label: this.translateService.instant('lblCardNumber'),
            exp_label: this.translateService.instant('lblExpiry'),
            cvd_label: this.translateService.instant('lblCvv'),
            css_textbox_pan: 'width:100%;',
            css_textbox_exp: 'width:100px;',
            css_textbox_cvd: 'width:60px;margin-bottom:0;',
            enable_exp: '1',
            enable_cvd: '1',
            enable_exp_formatting: '1',
            enable_cc_formatting: '0',
        };

        const queryString = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');

        console.log(`${baseUrl}?${queryString} frameborder='0'`);

        return this.sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}?${queryString} frameborder='0'`);
    }
}
