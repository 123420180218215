import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LogicalUnitInitMixin } from '../../../../gamma/logicalunit';

export interface IU2000_CreditCardTokenResponse {
    token: string;
    maskedCardNumber: string;
    cardType?: string;
}

@Component({
    selector: 'u2000-creditcardtoken',
    templateUrl: 'U2000_creditcardtoken.component.html',
    styleUrls: ['U2000_creditcardtoken.component.scss'],
})
export class U2000_CreditCardTokenComponent {
    // There to avoid using the constructor's name that is not available when minified.
    static className = 'CreditCardToken';

    @Input() processorId: number;
    @Input() service: LogicalUnitInitMixin;
    @Input() form: UntypedFormGroup;
    @Input() parentFormId: string;
    @Input() elementRef: ElementRef;
    @Input() callerLogicalUnitId: string;
    @Input() monerisHostedTokenizationUrl: string;
    @Input() monerisHostedTokenizationId: string;
    @Output() submitEvent: EventEmitter<IU2000_CreditCardTokenResponse> = new EventEmitter();

    get valid(): boolean {
        return true; //this.bamboraController.valid && this.form.valid;
    }

    fowardSubmitEvent(event: any) {
        this.submitEvent.emit(event);
    }

    onError() {
        // this.bamboraController.clearFields();
    }
}
