export const languageHashValues= {
  "GLOBAL": ".d903b6687d455ae8e2e9",
  "U4302_TRANSACTIONSDETAIL": ".95657da6215279c4b268",
  "U4400_PAYMENT": ".0ebf06d6e635437b8666",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".a54bafc4918ec8257c73",
  "U2117_PADAGREEMENTINVITATION": ".cb99498be560f5b5aa70",
  "U2000_PAD": ".90a7c9da325283ae2bf1",
  "U4402_INVOICEPORTALPAYMENT": ".4041a71ea7115d270c90",
  "U2000_BANKACCOUNTCREATION": ".0b057f6c68916223be58",
  "U2011_LOGIN": ".c242750dd578a2c2710d",
  "U2012_LOGINHELP": ".165df7538a4a39bb9cf8",
  "U2014_SECURITY": ".4bf296f50e24737ad962",
  "U2086_EFTPENDINGPAYMENTS": ".50835a5aeedbd4f83e8c",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".8ed903cd1bdbd1f3cb4a",
  "U2110_ACCOUNTCREATION": ".28d4e7161bc7d1fe4268",
  "U2121_INDIVIDUALPROFILE": ".ffb7c0b95163ea99c71f",
  "U2131_ORGANIZATIONPROFILE": ".f26d9fa8e123ca774b9a",
  "U2112_ERPBINDING": ".14562cde5f42734e642b",
  "U2113_SUPPLIERINVITATION": ".2cd3cb3472316505e9dc",
  "U2118_RBBINDING": ".683af7dd851784478698",
  "U2132_AFFILIATION": ".5570900a448226e8dbf0",
  "U2133_BUSINESSLINKS": ".6268ee144030ae4a6eb2",
  "U2181": ".cf1d77eaf1f47b02e21a",
  "U2211_SUBSCRIPTIONOFFER": ".89574b2423f671a47b99",
  "U2212_SUBSCRIPTIONFILTER": ".2bf6e10b20dd44056602",
  "U2213_SUBSCRIPTION": ".2aabb136ce814484da7e",
  "U4111_CREDITCARDADDITION": ".9a290caaf62bc92e3037",
  "U4112_CREDITCARDDETAIL": ".75f7c588cffa3dc7f7e2",
  "U4121_BANKACCOUNTADDITION": ".8c33dacd313bb9923ec8",
  "U4122_BANKACCOUNTDETAIL": ".1cacb0868ef8a2053afa",
  "U4303_ANONYMOUSTRANSACTION": ".795fdca6cf09f41c3a8c",
  "U4401_PAYMENTGATEWAY": ".8c63c7f8e9e85e46f427",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".6127246bf2dd0a5d610d",
  "U5000_RECEIVABLE": ".d52750fc506693e95af7",
  "U5101_STATEMENTOFACCOUNT": ".28d56ce3bdbb5140a50f",
  "U5102_INVOICE": ".23771fdd56fa929a1d19",
  "U5103_PADAGREEMENT": ".fd40b33bbda79abd7dcd",
  "U5121_SENTINVOICE": ".8262dfaea74ffb7c3d37",
  "U5124_PGRECEIVEDPAYMENT": ".f10b86369c2f254543b5",
  "U5120_INVOICES": ".08334e03a46c588df1a5",
  "U5123_PADAGREEMENTCONSOLE": ".9c70d3bf501eabd66ef8",
  "U5132_PAYMENTRECONCILIATION": ".b2903b15d6bc6d6de7b2",
  "U5133_BANKRECONCILIATION": ".0f589517600f4be372db",
  "U6112_BUSINESSLINK": ".85475bee547b05cb291a",
  "U6113_SUPPLIERRECORD": ".aff7f60e71e82ecdddf1",
  "U6211_PAYLOT": ".da9577313ed227021fb3",
  "U6311_PURCHASE": ".181e110dfea7b69933db",
  "U2115_AFFILIATIONINVITATION": ".84e007841d368568ff2e",
  "U6100_PAYABLE": ".04cb905ecf50a6ba9448",
  "U6131_SENTPAYMENT": ".67f9ed5bd3fad2bf21e3",
  "U6191_SENTPAYMENTREPORT": ".e41c48aeb5556063fbd2",
  "U6221_SENTPAYMENT": ".d667a7ce5167a248b82a",
  "U6422_INTERACSENTPAYMENT": ".117be608cc8fb0a651c4",
  "U6531_TRANSACTIONPAYMENT": ".0b9f10c074babb48b08f",
  "U5131_RECEIVEDPAYMENT": ".30d8afb565cd4649e676",
  "U5191_RECEIVEDPAYMENTREPORT": ".f0282f8459c8f5b9d963",
  "U2001_HOME": ".2f896897de368a4e31c3",
  "U2024_REPORTLOG": ".4e5046d6aac01156aaa8",
  "U2111_INVOICEPORTALBINDING": ".3a7a258dd74e302d3be2",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".517d01081c144e540ed8",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".7ae8d9ce3913fead09c4",
  "U6511_TRANSACTIONPARTY": ".af320f3ed7acce182fdc",
  "U6212_PAYROLLPARTY": ".913f5d22eaef47ed6fc2",
  "U6121_EFTLOT": ".3706c122da3600e7b14b",
  "U6412_INTERACFILE": ".c39f88ca673952d0ed89",
  "U6122_EFTAPPROVAL": ".21f7fd17112885afbbe9",
  "U6512_TRANSACTIONLOT": ".3b255fd14c1128c35178",
  "U6123_EFTAPPROVAL": ".073affa98c7d68d2c0fa",
  "U6123_EFTAPPROVALMOBILE": ".f0c8a846419461d93934",
  "U2012_LOGIN": ".398478dd01df75c6de40",
  "U2000_PAYMENT": ".f3aa009029531b4d376c",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".9f5b79b3664c6bc77a30",
  "U2000_PAYMENTMODEUPDATE": ".ea73aed2249d8b5ce601",
  "U6114_TELUSBILLER": ".4e8aa88774fac4ef46cc",
  "U4403_CREDITCARDTERMINAL": ".57212f8764774489b13a",
  "U4404_INTERACTERMINAL": ".8dc790553bff54f36788",
  "U2000_SAMPLECHEQUESELECTION": ".be47e573988a8e0cfa4d",
  "U2023_TUTORIALVIDEO": ".cea7e1ec21bdab4ea3ce",
  "U2134_ORGANIZATIONBILLING": ".f4be598616840eb136ab",
  "U6414_BANKACCOUNTWALLET": ".174df40af1161ff0ebf1",
  "U2022_INSTANTACCOUNTVERIFICATION": ".cd124ce338d69a6c87e3",
  "U6532_PENDINGTRANSACTION": ".c88e3fbf51b9bd7c3a62",
  "U4301_TRANSACTIONSSUMMARY": ".a9fec3617101363a6be9",
  "U5111_SENTINVOICESTRACKING": ".34d87db34b68bafc94c6",
  "U2026_NETPROMOTERSCORE": ".f5682b525682166b39b8",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".4c9af8f1a364d609a62a",
  "U2000_INTRO": ".95af3aaabd8444434e97",
  "U2000_ELECTRONICSIGNATURE": ".5aef2ea56293f3268e8e",
  "U2000_VERIFYEMAIL": ".a1095a2c33445f2ed82b",
  "U2200_SUBSCRIPTION": ".4372d44bc406ec93b312",
  "U2028_RELEASENOTE": ".4d42e86f92c258f0a179",
  "U2000_VERIFYIDENTITY": ".3eb929990761fae68a53",
  "U2000_ATTACHMENTVIEWER": ".9005200fd16950eac8bd",
  "U2212_PLANLIST": ".e8142ee5fcb9984a220a",
  "U3002_TAXBILLSEARCHRESULT": ".079748561cca57794187"
}